import { createAction } from "@reduxjs/toolkit";

export const chatHistoryReset = createAction("CHAT_HISTORY_RESET");
export const chatHistoryLoading = createAction("CHAT_HISTORY_LOADING");
export const chatHistorySuccess = createAction("CHAT_HISTORY_SUCCESS");
export const chatHistoryFailed = createAction("CHAT_HISTORY_FAILED");
export const resetUserChat = createAction("RESET_USER_CHAT");
export const resetUserChatSuccess = createAction("RESET_USER_CHAT_SUCESS");
export const resetUserChatFailed = createAction("RESET_USER_CHAT_FAILED");

export const aiSearchReset = createAction("AI_SEARCH_RESET");
export const aiSearchLoading = createAction("AI_SEARCH_LOADING");
export const aiSearchSuccess = createAction("AI_SEARCH_SUCCESS");
export const aiSearchFailed = createAction("AI_SEARCH_FAILED");

export const documentListReset = createAction("DOCUMENT_LIST_RESET");
export const documentListLoading = createAction("DOCUMENT_LIST_LOADING");
export const documentListSuccess = createAction("DOCUMENT_LIST_SUCCESS");
export const documentListFailed = createAction("DOCUMENT_LIST_FAILED");

export const feedbackResetAction = createAction("FEEDBACK_RESET");
export const feedbackLoadingAction = createAction("FEEDBACK_LOADING");
export const feedbackSuccessAction = createAction("FEEDBACK_SUCCESS");
export const feedbackFailedAction = createAction("FEEDBACK_FAILED");

export const welcomeMsgResetAction = createAction("WELCOME_MESSAGE_RESET");
export const welcomeMsgLoadingAction = createAction("WELCOME_MESSAGE_LOADING");
export const welcomeMsgSuccessAction = createAction("WELCOME_MESSAGE_SUCCESS");
export const welcomeMsgFailedAction = createAction("WELCOME_MESSAGE_FAILED");
