/*eslint-disable*/
import http from "axios";
// import { defaultTimeoutAPI } from "utils/Constants";

const defaultTimeoutAPI = 20000;

class YBApi {
  static baseHeaders() {
    return {
      "Content-Type": "application/json",
    };
  }

  static baseUrl() {
    return "";
  }
  static get(
    route,
    headers,
    params,
    timeout,
    responseType = null,
    encryptData,
    decryptData,
  ) {
    return this.api(
      "get",
      route,
      headers,
      params,
      {},
      timeout,
      responseType,
      encryptData,
      decryptData,
    );
  }

  static put(
    route,
    headers,
    params,
    data,
    timeout,
    responseType = null,
    encryptData,
    decryptData,
  ) {
    return this.api(
      "put",
      route,
      headers,
      params,
      data,
      timeout,
      responseType,
      encryptData,
      decryptData,
    );
  }

  static post(
    route,
    headers,
    params,
    data,
    timeout,
    responseType = null,
    encryptData,
    decryptData,
  ) {
    return this.api(
      "post",
      route,
      headers,
      params,
      data,
      timeout,
      responseType,
      encryptData,
      decryptData,
    );
  }

  static patch(route, headers, params, data, timeout, responseType = null) {
    return this.api(
      "patch",
      route,
      headers,
      params,
      data,
      timeout,
      responseType,
    );
  }

  static delete(route, headers, params, data, timeout, responseType = null) {
    return this.api(
      "delete",
      route,
      headers,
      params,
      data,
      timeout,
      responseType,
    );
  }

  static api(
    requestType,
    route,
    headers,
    params,
    data,
    timeout = defaultTimeoutAPI,
    responseType,
    encryptData,
    decryptData,
  ) {
    const host = YBApi.baseUrl();
    const url = `${host}${route}`;
    const baseHeaders = YBApi.baseHeaders();

    const requestConfig = {
      headers: headers ? Object.assign({}, baseHeaders, headers) : baseHeaders,
    };

    if (params) {
      requestConfig.params = params;
    }

    if (responseType) {
      requestConfig.responseType = responseType;
    }

    http.create();
    http.defaults.timeout = timeout;

    if (requestType === "get" || requestType === "delete") {
      return http[requestType](url, requestConfig)
        .then((response) => {
          let decryptResponce = null;
          if (decryptData) {
            decryptResponce = {
              ...response,
              data: response.data.data,
            };
          }
          return decryptData ? decryptResponce : response;
        })
        .catch((error) => {
          // console.log("error for get", error);
          // const _error = customErrorHandler.getErrorDetails(error);
          return Promise.reject(error);
        });
    }

    return http[requestType](url, encryptData ? data : data, requestConfig)
      .then((response) => {
        let decryptResponce = null;
        if (decryptData) {
          decryptResponce = {
            ...response,
            data: response.data,
          };
        }
        return decryptData ? decryptResponce : response;
      })
      .catch((error) => {
        // console.log("error for post", error);
        // const _error = customErrorHandler.getErrorDetails(error);
        return Promise.reject(error);
      });
  }
}

export default YBApi;
