import React from "react";
import icon8 from "../../images/icons8-three-dots-24.png";
import resetWhiteIcon from "../../images/reset_white.svg";
import resetIcon from "../../images/reset.svg";
import genieIcon from "../../images/genie.svg";
import minimizeIcon from "../../images/minimize.svg";
import maximizeIcon from "../../images/maximize.svg";
import crossIcon from "../../images/cross.svg";
import styles from "./Header.module.css";
import classNames from "classnames";

function Header(props) {
  const close = () => {};
  return (
    <div className={styles.header}>
      <div className={styles.webHeader}>
        <div className={styles.headerTitle}>
          <div className={styles.headerTitle1}>ASK GENIE</div>
          {/* <div className={styles.headerTitle2}>Ask Genie Search</div> */}
        </div>
        <div className={styles.resetChatHistory} onClick={props.resetUserChat0}>
          <div className={styles.resetImg}>
            <img src={resetIcon} />
          </div>
          <div className={styles.resetText}>Reset</div>
        </div>
        <div className={styles.mobileMenu} onClick={props.mobileMenuClicked}>
          <img src={icon8} />
        </div>
      </div>
      <div className={styles.mobileHeader}>
        <div className={styles.mobileHeaderLeft}>
          <div className={styles.headerLogo}>
            <div className={styles.headerLogoInner}>
              <img src={genieIcon} />
            </div>
            {/* <img src={"https://yesrobot.yesbank.in/Content/Images/support.png"} /> */}
          </div>
          <div className={styles.headerText}>ASK GENIE</div>
        </div>
        <div className={styles.mobileHeaderRight}>
          <div
            className={classNames(styles.mhrItem, styles.resetImg)}
            onClick={props.resetUserChat0}
          >
            <img src={resetWhiteIcon} />
          </div>
          {/* <div className={classNames(styles.mhrItem, styles.close)}><img src={crossIcon} /></div>
          <div className={classNames(styles.mhrItem, styles.maxmize)}><img src={maximizeIcon} /></div>
          <div className={classNames(styles.mhrItem, styles.minimize)}><img src={minimizeIcon} /></div> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
