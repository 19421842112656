import React, { useState } from "react";
import classNames from "classnames";
import searchActive from "../../images/searchActive.svg";
import reset from "../../images/resetSearchFiles.svg";
import searchDisabled from "../../images/searchDisabled.svg";
import styles from "./SearchBox.module.css";

function SearchBox(props) {
  const [searchText, setSearchText] = useState("");

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      searchO();
    }
  };

  const searchO = () => {
    props.searchDocuments(searchText.trim());
  };
  const resetSearch = () => {
    props.searchDocuments("");
    setSearchText("");
  };
  return (
    <div className={styles.documentSearch}>
      <div className={styles.documentSearchInner}>
        <div className={classNames(styles.searchInput)}>
          <input
            type="text"
            placeholder="Search here"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
            onKeyUp={(event) => {
              if (searchText) {
                handleKeyUp(event);
              }
            }}
          />
        </div>
        <div
          className={classNames(styles.searchIcon)}
          onClick={() => {
            if (searchText) {
              searchO();
            }
            // setSearchText("");
          }}
        >
          <img src={searchText ? searchActive : searchDisabled} alt="image" />
        </div>
        <div className={classNames(styles.searchIcon)} onClick={resetSearch}>
          <img src={searchText ? reset : reset} alt="Reset" />
        </div>
      </div>
    </div>
  );
}

export default SearchBox;
