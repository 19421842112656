import React, { useEffect, useState } from "react";
import styles from "./ChatDocumentsContainer.module.css";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "../components/SearchBox/SearchBox";
import DocumentListing from "../components/DocumentListing/DocumentListing";
import Pagination from "../components/Pagination/Pagination";
import {
  resetDocumentList,
  showDocumentListLoading,
  getDocumentListData,
} from "../actions/documentList";

function ChatDocumentsContainer(props) {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(10);

  const documentList = useSelector((state) => {
    return state.documentList;
  });

  useEffect(() => {
    searchDocuments("");
  }, [pageNo]);

  useEffect(() => {
    if (
      documentList &&
      documentList.documentListData &&
      documentList.documentListData
    ) {
      setpageSize(
        documentList &&
          documentList.documentListData &&
          documentList.documentListData.pageSize,
      );
      setTotalCount(
        documentList &&
          documentList.documentListData &&
          documentList.documentListData.totalDocuments,
      );
    }
  });

  const searchDocuments = (searchText) => {
    // debugger; // eslint-disable-line no-debugger
    dispatch(resetDocumentList());
    dispatch(showDocumentListLoading());
    if (searchText) {
      dispatch(
        getDocumentListData({
          searchText: searchText,
          pageNo: pageNo,
          pageSize: 10,
        }),
      );
      // Reset page number
      setPageNo(1);
    } else {
      dispatch(
        getDocumentListData({
          searchText: searchText,
          pageNo: pageNo,
          pageSize: 10,
        }),
      );
    }
  };
  return (
    <div className={styles.chatDocumentsContainer}>
      <div className={styles.header}>Available Documents</div>
      <SearchBox searchDocuments={searchDocuments} />
      <DocumentListing />
      {totalCount ? (
        <Pagination
          totalCount={totalCount}
          onPageChange={(item) => {
            // setPageNo(item.from - 1);
            setPageNo(item.from);
          }}
          pageSize={pageSize}
          currentPage={pageNo}
          paginationOptions={[{ label: 10, value: 10 }]}
        />
      ) : null}
    </div>
  );
}

export default ChatDocumentsContainer;
