import YBApi from "../libs/api";
import config from "../libs/config";
import {
  chatHistoryReset,
  chatHistoryLoading,
  chatHistorySuccess,
  chatHistoryFailed,
  aiSearchReset,
  aiSearchLoading,
  aiSearchSuccess,
  aiSearchFailed,
  resetUserChatSuccess,
  resetUserChatFailed,
  feedbackFailedAction,
  feedbackResetAction,
  feedbackSuccessAction,
  feedbackLoadingAction,
  welcomeMsgResetAction,
  welcomeMsgLoadingAction,
  welcomeMsgFailedAction,
  welcomeMsgSuccessAction,
} from "../actions/actionCreator";

export function resetChatHistory() {
  return (dispatch) => {
    dispatch(chatHistoryReset());
  };
}
export function showChatHistoryLoading() {
  return (dispatch) => {
    dispatch(chatHistoryLoading());
  };
}
export function getChatHistoryData(params, callbackFun) {
  return (dispatch) => {
    YBApi.post(
      `${process.env.REACT_APP_BASE_URL}${config.endpoint.getSearchHistory}`,
      null,
      null,
      params,
    ).then(
      (response) => {
        setTimeout(() => {
          dispatch(chatHistorySuccess(response.data));
          callbackFun(response);
        }, 1000);
      },
      (error) => {
        dispatch(chatHistoryFailed());
      },
    );
  };
}
export function resetAIsearch() {
  return (dispatch) => {
    dispatch(aiSearchReset());
  };
}
export function showAIsearchLoading() {
  return (dispatch) => {
    dispatch(aiSearchLoading());
  };
}
export function searchAItext(params) {
  return (dispatch) => {
    YBApi.post(
      `${process.env.REACT_APP_BASE_URL}${config.endpoint.aiSearch}`,
      null,
      null,
      params,
    ).then(
      (response) => {
        setTimeout(() => {
          dispatch(aiSearchSuccess(response.data));
        }, 2000);
      },
      (error) => {
        dispatch(aiSearchFailed());
      },
    );
  };
}
export function resetUserChat(body) {
  return (dispatch) => {
    YBApi.put(
      `${process.env.REACT_APP_BASE_URL}${config.endpoint.resetChatHistory}`,
      null,
      null,
      body,
    ).then(
      (response) => {
        dispatch(resetUserChatSuccess(response.data));
      },
      (error) => {
        dispatch(resetUserChatFailed(error));
      },
    );
  };
}
export function feedbackReset() {
  return (dispatch) => {
    dispatch(feedbackResetAction());
  };
}
export function feedbackLoading() {
  return (dispatch) => {
    dispatch(feedbackLoadingAction());
  };
}
export function feedback(body) {
  return (dispatch) => {
    YBApi.put(
      `${process.env.REACT_APP_BASE_URL}${config.endpoint.giveFeedback}`,
      null,
      null,
      body,
    ).then(
      (response) => {
        dispatch(feedbackSuccessAction(response.data));
      },
      (error) => {
        dispatch(feedbackFailedAction(error));
      },
    );
  };
}

export function welcomeMsgReset() {
  return (dispatch) => {
    dispatch(welcomeMsgResetAction());
  };
}
export function welcomeMsgLoading() {
  return (dispatch) => {
    dispatch(welcomeMsgLoadingAction());
  };
}
export function getWelcomMsg(params, callback) {
  return (dispatch) => {
    YBApi.post(
      `${process.env.REACT_APP_BASE_URL}${config.endpoint.welcomeMsg}`,
      null,
      null,
      params,
    ).then(
      (response) => {
        setTimeout(() => {
          dispatch(welcomeMsgSuccessAction(response.data));
          callback();
        }, 2000);
      },
      (error) => {
        dispatch(welcomeMsgFailedAction());
      },
    );
  };
}
