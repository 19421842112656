import React, { useState } from "react";
import styles from "./NoDataFound.module.css";

function NoDataFound(props) {
  return (
    <div className={styles.noDataFound}>
      <span>{props.text}</span>
    </div>
  );
}

export default NoDataFound;
