import { createReducer } from "@reduxjs/toolkit";
import {
  aiSearchReset,
  aiSearchLoading,
  aiSearchFailed,
  aiSearchSuccess,
} from "../actions/actionCreator";

const initialState = {
  isAIsearchLoading: false,
  isAIsearchError: false,
  aiSearchData: {},
};
const aiSearchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(aiSearchReset, (state) => {
      return {
        ...initialState,
      };
    })
    .addCase(aiSearchLoading, (state) => {
      return {
        ...state,
        isAIsearchLoading: true,
        isAIsearchError: false,
        aiSearchData: {},
      };
    })
    .addCase(aiSearchSuccess, (state, action) => {
      return {
        ...state,
        isAIsearchLoading: false,
        isAIsearchError: false,
        aiSearchData: action.payload ? action.payload : {},
      };
    })
    .addCase(aiSearchFailed, (state) => {
      return {
        ...state,
        isAIsearchLoading: false,
        isAIsearchError: true,
        aiSearchData: {},
      };
    });
  // .addCase(resetChatHistoryFailed, (state) => {
  //     return {
  //         ...state,
  //         isAIsearchLoading: false,
  //         isAIsearchError: true,
  //         aiSearchData: [],
  //     }
  // });
});

export default aiSearchReducer;
