const config = {
  dev: process.env.LOCAL,
  stage: process.env.STAGE,
  prod: process.env.PRODUCTION,
  endpoint: {
    aiSearch: "/api/aiengine/message",
    getSearchHistory: "/api/aiengine/session/history",
    welcomeMsg: "/api/aiengine/message",
    searchDocument: "/api/aiengine/knowledgebase/documents",
    resetChatHistory: "/api/aiengine/session/reset",
    giveFeedback: "/api/aiengine/feedback",
  },
};
export default config;
