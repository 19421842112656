import { createReducer } from "@reduxjs/toolkit";
import {
  feedbackResetAction,
  feedbackSuccessAction,
  feedbackLoadingAction,
  feedbackFailedAction,
} from "../actions/actionCreator";

const initialState = {
  isFeedbackLoading: false,
  isFeedbackError: false,
  feedbackSuccess: false,
  feedbackData: [],
};
const feedbackReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(feedbackResetAction, (state) => {
      return {
        ...initialState,
      };
    })
    .addCase(feedbackLoadingAction, (state) => {
      return {
        ...state,
        isFeedbackLoading: true,
        isFeedbackError: false,
        feedbackSuccess: false,
        feedbackData: [],
      };
    })
    .addCase(feedbackSuccessAction, (state, action) => {
      return {
        ...state,
        isFeedbackLoading: false,
        isFeedbackError: false,
        feedbackSuccess: true,
        feedbackData: action.payload,
      };
    })
    .addCase(feedbackFailedAction, (state) => {
      return {
        ...state,
        isFeedbackLoading: false,
        isFeedbackError: true,
        feedbackSuccess: false,
        feedbackData: [],
      };
    });
});

export default feedbackReducer;
