import YBApi from "../libs/api";
import config from "../libs/config";

import {
  documentListReset,
  documentListLoading,
  documentListSuccess,
  documentListFailed,
} from "../actions/actionCreator";

export function resetDocumentList() {
  return (dispatch) => {
    dispatch(documentListReset());
  };
}
export function showDocumentListLoading() {
  return (dispatch) => {
    dispatch(documentListLoading());
  };
}
export function getDocumentListData(params) {
  return (dispatch) => {
    YBApi.post(
      // `${config.baseURL}${config.endpoint.searchDocument}`,
      `${process.env.REACT_APP_BASE_URL}${config.endpoint.searchDocument}`,
      null,
      null,
      params,
    ).then(
      (response) => {
        setTimeout(() => {
          dispatch(documentListSuccess(response.data));
        }, 1000);
      },
      (error) => {
        dispatch(documentListFailed());
      },
    );
  };
}
