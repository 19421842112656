export const copy = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    class="copyTool"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z"
      fill="#2b61ab"
    ></path>
  </svg>
);
export const refresh = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-md"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 2.5C5.05228 2.5 5.5 2.94772 5.5 3.5V5.07196C7.19872 3.47759 9.48483 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C7.1307 21.5 3.11828 17.8375 2.565 13.1164C2.50071 12.5679 2.89327 12.0711 3.4418 12.0068C3.99033 11.9425 4.48712 12.3351 4.5514 12.8836C4.98798 16.6089 8.15708 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C9.7796 4.5 7.7836 5.46469 6.40954 7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9 9H4.5C3.96064 9 3.52101 8.57299 3.50073 8.03859C3.49983 8.01771 3.49958 7.99677 3.5 7.9758V3.5C3.5 2.94772 3.94771 2.5 4.5 2.5Z"
      fill="#2b61ab"
    ></path>
  </svg>
);
export const positiveFeed = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-md"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1318 2.50389C12.3321 2.15338 12.7235 1.95768 13.124 2.00775L13.5778 2.06447C16.0449 2.37286 17.636 4.83353 16.9048 7.20993L16.354 8.99999H17.0722C19.7097 8.99999 21.6253 11.5079 20.9313 14.0525L19.5677 19.0525C19.0931 20.7927 17.5124 22 15.7086 22H6C4.34315 22 3 20.6568 3 19V12C3 10.3431 4.34315 8.99999 6 8.99999H8C8.25952 8.99999 8.49914 8.86094 8.6279 8.63561L12.1318 2.50389ZM10 20H15.7086C16.6105 20 17.4008 19.3964 17.6381 18.5262L19.0018 13.5262C19.3488 12.2539 18.391 11 17.0722 11H15C14.6827 11 14.3841 10.8494 14.1956 10.5941C14.0071 10.3388 13.9509 10.0092 14.0442 9.70591L14.9932 6.62175C15.3384 5.49984 14.6484 4.34036 13.5319 4.08468L10.3644 9.62789C10.0522 10.1742 9.56691 10.5859 9 10.8098V19C9 19.5523 9.44772 20 10 20ZM7 11V19C7 19.3506 7.06015 19.6872 7.17071 20H6C5.44772 20 5 19.5523 5 19V12C5 11.4477 5.44772 11 6 11H7Z"
      fill="#2b61ab"
    ></path>
  </svg>
);
export const positiveFeedFill = (
  <svg
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Group-129"
        transform="translate(7.1329, 8) scale(-1, -1) translate(-7.1329, -8)translate(0.5008, 0.5)"
        fill="#2B61AB"
      >
        <path
          d="M8.5515595,0 L3.17125564,0 C2.32122454,0 1.57635639,0.568924243 1.35270368,1.38900303 L0.0674875683,6.10148725 C-0.259546795,7.30052214 0.643141185,8.48240372 1.88604895,8.48240372 L3.83912241,8.48240372 C4.13821197,8.48240372 4.41955445,8.62434261 4.59725138,8.86496013 C4.77493889,9.10557765 4.82787851,9.41622213 4.73992541,9.70207913 L3.84553134,12.6088104 C3.52017461,13.6662834 4.17047337,14.7590055 5.22278153,15 L8.2081164,9.7755933 C8.50236156,9.26071139 8.95975162,8.87268854 9.4940488,8.66166519 L9.4940488,0.942489303 C9.4940488,0.421971311 9.07209634,0 8.5515595,0 Z"
          id="Path"
        ></path>
        <path
          d="M11.3790274,8.48240372 L11.3790274,0.942489303 C11.3790274,0.612024279 11.3223838,0.294791804 11.2181445,0 L12.3215167,0 C12.8420536,0 13.264006,0.421971311 13.264006,0.942489303 L13.264006,7.53991442 C13.264006,8.06045126 12.8420536,8.48240372 12.3215167,8.48240372 L11.3790274,8.48240372 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);
export const negativeFeed = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-md"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z"
      fill="#2b61ab"
    ></path>
  </svg>
);
export const negativeFeedFill = (
  <svg
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group 129</title>
    <g id="Web" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-129" transform="translate(0.5008, 0.5)" fill="#2B61AB">
        <path
          d="M8.5515595,0 L3.17125564,0 C2.32122454,0 1.57635639,0.568924243 1.35270368,1.38900303 L0.0674875683,6.10148725 C-0.259546795,7.30052214 0.643141185,8.48240372 1.88604895,8.48240372 L3.83912241,8.48240372 C4.13821197,8.48240372 4.41955445,8.62434261 4.59725138,8.86496013 C4.77493889,9.10557765 4.82787851,9.41622213 4.73992541,9.70207913 L3.84553134,12.6088104 C3.52017461,13.6662834 4.17047337,14.7590055 5.22278153,15 L8.2081164,9.7755933 C8.50236156,9.26071139 8.95975162,8.87268854 9.4940488,8.66166519 L9.4940488,0.942489303 C9.4940488,0.421971311 9.07209634,0 8.5515595,0 Z"
          id="Path"
        ></path>
        <path
          d="M11.3790274,8.48240372 L11.3790274,0.942489303 C11.3790274,0.612024279 11.3223838,0.294791804 11.2181445,0 L12.3215167,0 C12.8420536,0 13.264006,0.421971311 13.264006,0.942489303 L13.264006,7.53991442 C13.264006,8.06045126 12.8420536,8.48240372 12.3215167,8.48240372 L11.3790274,8.48240372 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);
