import React, { useState, useEffect, Suspense } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styles from "./LayoutPageContainer.module.css";
import ChatWindow from "./ChatWindow";
import ChatDocumentsContainer from "./ChatDocumentsContainer";
import { resetUserChat } from "../actions/chatAction";
import Header from "../components/Header/Header";
import Loader from "../components/Loader/Loader";
import ChatWindowLeft from "./ChatWindowLeft/ChatWindowLeft";

function LayoutPageContainer(props) {
  const dispatch = useDispatch();
  const [channel] = useState("GENIE");
  const [showMobDocView, setShowMobDocView] = useState(false);
  const [searchParams] = useSearchParams();

  const urlParams = new URLSearchParams(window.location.search);
  // const userADID = "JSV1080413";
  // const userName = "JASVINDER SHARMA";
  const userADID = urlParams.get("adid");
  const userName = decodeURIComponent(urlParams.get("name"));
  const paramSearchText = urlParams.get("searchText");

  const mobileMenuClicked = () => {
    setShowMobDocView(!showMobDocView);
  };
  const resetUserChat0 = () => {
    const body = { sessionId: [userADID] };
    dispatch(resetUserChat(body));
  };

  return (
    <div className={styles.mainContainer}>
      <Header
        resetUserChat0={resetUserChat0}
        mobileMenuClicked={mobileMenuClicked}
      />
      <div className={styles.mainBody}>
        <Suspense fallback={<Loader />}>
          <div className={styles.chatWindow}>
            <div className={styles.chatWindowLeft}>
              <ChatWindowLeft />
            </div>
            <div className={styles.chatWindowRight}>
              <ChatWindow
                userName={userName}
                userADID={userADID}
                channel={channel}
                paramSearchText={paramSearchText}
              />
            </div>

            {showMobDocView ? (
              <div className={styles.mobileChatDocumentWindow}>
                <ChatDocumentsContainer />
              </div>
            ) : null}
          </div>
        </Suspense>
        <Suspense fallback={<Loader />}>
          <div className={styles.chatDocumentWindow}>
            <ChatDocumentsContainer
              userName={userName}
              userADID={userADID}
              channel={channel}
            />
          </div>
        </Suspense>
      </div>
    </div>
  );
}
export default LayoutPageContainer;
