import React, { useState } from "react";
import styles from "./Error.module.css";

function Error(props) {
  return (
    <div className={styles.error}>
      <span>{props.text}</span>
    </div>
  );
}

export default Error;
