import { configureStore } from "@reduxjs/toolkit";
import chatHistory from "../reducer/chatHistoryReducer";
import aiSearch from "../reducer/aiSearchReducer";
import documentList from "../reducer/documentListReducer";
import feedback from "../reducer/feedbackReducer";
import welcomeMsg from "../reducer/welcomeMsgReducer";
// import logger from 'redux-logger';
// import thunk from 'redux-thunk';

const store = configureStore({
  reducer: {
    aiSearch,
    chatHistory,
    documentList,
    feedback,
    welcomeMsg,
  },
  // middleware: [thunk, logger],
});

export default store;
