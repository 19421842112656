import React, { useState } from "react";
import classNames from "classnames";
import styles from "./Loader.module.css";
import animStyles from "../../libs/css/animation.module.css";

function Loader(props) {
  return (
    <div className={classNames(styles.loader)} style={props.style}>
      <div className={animStyles.spinner}></div>
    </div>
  );
}

export default Loader;
