import { createReducer } from "@reduxjs/toolkit";
import {
  chatHistoryLoading,
  chatHistoryReset,
  chatHistorySuccess,
  chatHistoryFailed,
  resetUserChatSuccess,
} from "../actions/actionCreator";

const initialState = {
  isChatHistoryLoading: false,
  isChatHistoryError: false,
  chatHistoryData: {},
};
const chatSlice = createReducer(initialState, (builder) => {
  builder
    // .addCase(getChatData, (state, action) => {
    .addCase(chatHistoryReset, (state) => {
      return {
        ...initialState,
      };
    })
    .addCase(chatHistoryLoading, (state) => {
      return {
        ...state,
        isChatHistoryLoading: true,
        isChatHistoryError: false,
        chatHistoryData: {},
      };
    })
    .addCase(chatHistorySuccess, (state, action) => {
      return {
        ...state,
        isChatHistoryLoading: false,
        isChatHistoryError: false,
        chatHistoryData: action.payload ? action.payload : null,
      };
    })
    .addCase(chatHistoryFailed, (state) => {
      return {
        ...state,
        isChatHistoryLoading: false,
        isChatHistoryError: true,
        chatHistoryData: {},
      };
    })
    .addCase(resetUserChatSuccess, (state) => {
      return {
        ...initialState,
      };
    });
});

export default chatSlice;
