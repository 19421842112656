import React, { useRef } from "react";
import styles from "./ChatTool.module.css";
import {
  copy,
  refresh,
  positiveFeed,
  negativeFeed,
  positiveFeedFill,
  negativeFeedFill,
} from "../../libs/svgIcons";
// import { getFormattedDateTime, convertUTCtolocal } from "../../libs/constant";

function ChatTool(props) {
  const copyBtn = useRef();
  return (
    <div className={styles.container}>
      {/* Positive Feedback */}
      {props.data.feedback === "POSITIVE" ? (
        <button className={styles.button}>{positiveFeedFill}</button>
      ) : null}
      {/* Negative Feedback */}
      {props.data.feedback === "NEGATIVE" ? (
        <button className={styles.button}>{negativeFeedFill}</button>
      ) : null}
      {/* Positive -  Negative Feedback */}
      {props.data.feedback !== "POSITIVE" &&
      props.data.feedback !== "NEGATIVE" ? (
        <>
          <button
            className={styles.button}
            title="Positive Response"
            onClick={() => {
              props.toolBtnClicked("POSITIVE", props.data);
            }}
          >
            {positiveFeed}
          </button>
          <button
            className={styles.button}
            title="Negative Response"
            onClick={() => {
              props.toolBtnClicked("NEGATIVE", props.data);
            }}
          >
            {negativeFeed}
          </button>
        </>
      ) : null}
      {/* <span>{getFormattedDateTime(convertUTCtolocal(props.data.createdOn))}</span> */}
      {/* Copy Button */}
      <button
        ref={copyBtn}
        className={styles.button}
        title="Copy"
        onClick={() => {
          props.toolBtnClicked("COPY", props.data);
        }}
      >
        {copy}
      </button>
      {/* Refresh Button */}
      <button
        className={styles.button}
        title="Regenerate"
        onClick={() => {
          props.toolBtnClicked("REGENERATE", props.data);
        }}
      >
        {refresh}
      </button>
    </div>
  );
}

export default ChatTool;
