import { createReducer } from "@reduxjs/toolkit";
import {
  welcomeMsgResetAction,
  welcomeMsgSuccessAction,
  welcomeMsgLoadingAction,
  welcomeMsgFailedAction,
} from "../actions/actionCreator";

const initialState = {
  isWelcomeMsgLoading: false,
  isWelcomeMsgError: false,
  welcomeMsgSuccess: false,
  welcomeMsgData: {},
};
const welcomeMsgReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(welcomeMsgResetAction, (state) => {
      return {
        ...initialState,
      };
    })
    .addCase(welcomeMsgLoadingAction, (state) => {
      return {
        ...state,
        isWelcomeMsgLoading: true,
        isWelcomeMsgError: false,
        welcomeMsgSuccess: false,
        welcomeMsgData: {},
      };
    })
    .addCase(welcomeMsgSuccessAction, (state, action) => {
      return {
        ...state,
        isWelcomeMsgLoading: false,
        isWelcomeMsgError: false,
        welcomeMsgSuccess: true,
        welcomeMsgData: action.payload ? action.payload[0] : {},
      };
    })
    .addCase(welcomeMsgFailedAction, (state) => {
      return {
        ...state,
        isWelcomeMsgLoading: false,
        isWelcomeMsgError: true,
        welcomeMsgSuccess: false,
        welcomeMsgData: {},
      };
    });
});

export default welcomeMsgReducer;
