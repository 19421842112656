import React, { useState } from "react";
import disclaimerIcon from "../../images/Disclaimer.svg";
import { generateUniqueId } from "../../libs/constant";
import styles from "./Disclaimer.module.css";
import Suggestions from "../Suggestions/Suggestions";

const disclaimers = [
  "May occasionally Generate incorrect information or Biased Content",
  "Knowledge Limited to Uploaded YBL Product/Program Documents",
];

function Disclaimer(props) {
  const [suggestions, setSuggestions] = useState(true);
  const closeSuggestionsBox = () => {
    setSuggestions(false);
    props.setDisclaimer(false);
  };
  return (
    <div className={styles.container}>
      {suggestions ? (
        <div className={styles.suggestions}>
          <Suggestions
            closeSuggestionsBox={closeSuggestionsBox}
            searchUserInput={props.searchUserInput}
          />
        </div>
      ) : null}
      <div className={styles.disclaimer}>
        <div className={styles.disclaimerHeading}>
          <div className={styles.disclaimerHeadingImg}>
            <img src={disclaimerIcon} alt="Disclaimer" />
          </div>
          <div className={styles.disclaimerHeadingText}>Disclaimer</div>
        </div>
        <div className={styles.disclaimerItems}>
          {disclaimers.map((item) => {
            return (
              <div key={generateUniqueId()} className={styles.disclaimerItem}>
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
