import React, { useState } from "react";
import classNames from "classnames";
import blackLeftArrow from "../../images/blackLeftArrow.svg";
import blackRightArrow from "../../images/blackRightArrow.svg";
import { usePagination, DOTS } from "../Pagination/usePagination";
import styles from "./Pagination.module.css";
// import PaginationWidget from '../PaginationWidget/PaginationWidget';
function Pagination(props) {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    paginationOptions,
    style,
    zIndex,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const onNext = () => {
    setCurrentPageNumber(currentPageNumber + 1);
    onPageChange({ size: pageSize, from: currentPage + 1 });
  };

  const onPrevious = () => {
    setCurrentPageNumber(currentPageNumber - 1);
    onPageChange({ size: pageSize, from: currentPage - 1 });
  };

  // const onDropDownChange = option => {
  //     onPageChange({ size: option.value, from: 0 });
  // };

  const onPageNumberClick = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
    onPageChange({ size: pageSize, from: pageNumber });
  };
  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={styles.pagination}>
      <div
        className={classNames(
          styles.pageItems,
          currentPage === 1 ? styles.disabled : "",
        )}
        onClick={currentPage === 1 ? null : onPrevious}
      >
        <img src={blackLeftArrow} />
      </div>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <div className={styles.pageItems}>{DOTS}</div>;
        }
        return (
          <div
            className={classNames(
              styles.pageItems,
              pageNumber === currentPage ? styles.selected : null,
            )}
            key={pageNumber}
            onClick={() => {
              onPageNumberClick(pageNumber);
            }}
          >
            {pageNumber}
          </div>
        );
      })}
      <div
        className={classNames(
          styles.pageItems,
          currentPage === lastPage ? styles.disabled : "",
        )}
        onClick={currentPage === lastPage ? null : onNext}
      >
        <img src={blackRightArrow} />
      </div>
    </div>
  );
}

export default Pagination;
