import React from "react";
import styles from "./ChatTimeLine.module.css";
import { getFormattedDateTime, convertUTCtolocal } from "../../libs/constant";

function ChatTimeLine(props) {
  return (
    <div className={styles.timeLine}>
      <div className={styles.timeText}>
        {getFormattedDateTime(convertUTCtolocal(props.dateTime))}
      </div>
    </div>
  );
}

export default ChatTimeLine;
