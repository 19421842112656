import React, { useRef, useState } from "react";
import classNames from "classnames";
const parse = require("html-react-parser").default;
import styles from "./ChatItem.module.css";
import animStyles from "../../libs/css/animation.module.css";
import youIcon from "../../images/You.svg";
import genieIcon from "../../images/genie.svg";
import ChatTool from "../ChatTool/ChatTool";
import { regexs } from "../../libs/constant";

function ChatItem(props) {
  const contentRef = useRef(null);
  const { regenerate, isPreloader } = props;

  const isCurrentUser = (item) => {
    return item.mode && item.mode.toLowerCase() === "user";
  };
  const hasOptionItems = (data) => {
    return data && data.options && data.options.length;
  };
  const copyContentText = () => {
    navigator.clipboard.writeText(contentRef.current.textContent);
  };
  const toolBtnClicked = (type, data) => {
    switch (type) {
      case "COPY":
        copyContentText(data);
        break;
      case "REGENERATE":
        regenerate(data);
        break;
      case "POSITIVE":
        props.sendFeedback(type, data.messageId);
        break;
      case "NEGATIVE":
        props.sendFeedback(type, data.messageId);
        break;
      default:
        return;
    }
  };
  const makeLinkForPdfUrl = (text) => {
    const item = text.replace(
      regexs.jsonString,
      (capturedString, firstGroup, secondGroup, thirdGroup, fourthGroup) => {
        const jsonItems = firstGroup ? JSON.parse(firstGroup) : null;
        let link0 = "";
        if (jsonItems) {
          jsonItems.map((item) => {
            const filename = item.filename && item.filename.split(".")[0];
            link0 += `<a href=${item.url} style="color:#2b61ab" target="_blank">${filename}</a> `;
          });
        }

        return link0;
        // if (secondGroup && fourthGroup) {
        //   return `<a href=${fourthGroup} style="color:#2b61ab" target="_blank">${secondGroup}</a>`;
        // }
        // if (secondGroup && !fourthGroup) {
        //   return `<span style="color:#b1b1b1">${secondGroup} </span>`;
        // }
      },
    );
    return item.replace(
      regexs.pdfURL2,
      `<a style="color:#2b61ab" href="$1">Link</a>`,
    );
  };
  const modifyText = (props) => {
    if (props.data && props.data.text) {
      let text0 = props.data.text.replace(
        /http\:\/\/localhost\:\d+/g,
        process.env.REACT_APP_FILE_BASE_URL,
      );
      let text = text0.replace(
        /((https|http)\:\/\/(.*?)\.pdf)/g,
        `<a style="color:#2b61ab" href="$1" target="_blank">Link</a>`,
      );
      const texts = text.split("\n");
      return texts.map((text) => {
        const brText = `<span>${text}<br></span>`;
        // return parse(makeLinkForPdfUrl(brText));
        return parse(brText);
      });
    } else {
      return null;
    }
  };
  return (
    <div
      className={classNames(
        styles.chatItem,
        // isCurrentUser(props.data) ? styles.rightSide : null,
      )}
    >
      <div
        className={classNames(
          styles.chatItemInner,
          // isCurrentUser(props.data) ? styles.rightSideReverse : null,
        )}
      >
        {/* User or ROBO */}
        <div
          className={
            isCurrentUser(props.data)
              ? styles.chatUserIcon
              : styles.chatGenieIcon
          }
        >
          <img
            src={isCurrentUser(props.data) ? youIcon : genieIcon}
            alt="Robo/User"
          />
        </div>
        {/* Content */}
        <div
          ref={contentRef}
          className={classNames(
            styles.chatItemContent,
            isPreloader ? styles.chatItemContent2 : null,
            // isCurrentUser(props.data) ? styles.chatItemContentReverse : null,
          )}
        >
          {/* User */}
          <div className={styles.userTitle}>
            {isCurrentUser(props.data) ? "You" : "Genie"}
          </div>
          {/* Text */}
          {isPreloader ? (
            <div className={animStyles.loader}></div>
          ) : (
            <div className={styles.textStyle}>{modifyText(props)}</div>
          )}
          {/* Options Buttons */}
          {hasOptionItems(props.data) ? (
            <div className={styles.chatOptions}>
              {props.data &&
                props.data.options.map((item) => {
                  return item.type && item.type.toLowerCase() === "link" ? (
                    <div
                      className={classNames(
                        styles.chatOption,
                        styles.chatOptionsLink,
                      )}
                    >
                      <a href={item.value} target="_blank">
                        {item.label}
                      </a>
                    </div>
                  ) : item.type && item.type.toLowerCase() === "text" ? (
                    <div
                      className={classNames(
                        styles.chatOption,
                        styles.chatOptionsText,
                      )}
                      onClick={() => {
                        props.searchUserInput(item.value);
                      }}
                    >
                      {item.label}
                    </div>
                  ) : null;
                })}
            </div>
          ) : null}
          {/* Tools buttons */}
          {/* {!isCurrentUser(props.data) && !isPreloader ? (
            <ChatTool toolBtnClicked={toolBtnClicked} data={props.data} />
          ) : null} */}
          {props.data.mode &&
          props.data.mode.toLowerCase() === "bot" &&
          !props.tool ? (
            <ChatTool toolBtnClicked={toolBtnClicked} data={props.data} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ChatItem;
