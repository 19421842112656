import { createReducer } from "@reduxjs/toolkit";
import {
  documentListLoading,
  documentListReset,
  documentListSuccess,
  documentListFailed,
} from "../actions/actionCreator";

const initialState = {
  isDocumentListLoading: false,
  isDocumentListError: false,
  documentListData: null,
};
const documentListSlice = createReducer(initialState, (builder) => {
  builder
    .addCase(documentListReset, () => {
      return {
        ...initialState,
      };
    })
    .addCase(documentListLoading, (state) => {
      return {
        ...state,
        isDocumentListLoading: true,
        isDocumentListError: false,
        documentListData: null,
      };
    })
    .addCase(documentListSuccess, (state, action) => {
      return {
        ...state,
        isDocumentListLoading: false,
        isDocumentListError: false,
        documentListData: action.payload,
      };
    })
    .addCase(documentListFailed, (state) => {
      return {
        ...state,
        isDocumentListLoading: false,
        isDocumentListError: true,
        documentListData: null,
      };
    });
});

export default documentListSlice;
