import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import logo from './logo.svg';
// https://react-redux.js.org/tutorials/quick-start
// https://www.mohammadfaisal.dev/blog/how-to-add-linting-and-formatting-for-your-react-app
// https://redux.js.org/introduction/why-rtk-is-redux-today
// https://create-react-app.dev/docs/adding-a-stylesheet

// https://dev.to/rexeze/how-to-build-a-real-time-chat-app-with-nodejs-socketio-and-mongodb-2kho
// https://socket.io/docs/v4/tutorial/step-3
// https://socket.io/docs/v4/
// https://bosctechlabs.com/scroll-to-an-element-in-react/
// https://prettier.io/docs/en/
// https://stackoverflow.com/questions/9153445/how-to-communicate-between-iframe-and-the-parent-site

import "./App.css";
import LayoutPageContainer from "./containers/LayoutPageContainer";
import NoPage from "./containers/NoPage";
// import { pauseBrowser } from './libs/constant';

function App() {
  console.log("Testing2");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutPageContainer />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
