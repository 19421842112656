import React from "react";
import styles from "./DocumentListing.module.css";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import Error from "../Error/Error";
import NoDataFound from "../NoDataFound/NoDataFound";
import {
  msgError,
  msgLoading,
  msgDataNotFound,
  getEnv,
} from "../../libs/constant";

function DocumentListing(props) {
  console.log("getEnvw", getEnv());
  const documentList = useSelector((state) => {
    return state.documentList;
  });
  const hasDocumentRecords = () => {
    return (
      documentList &&
      documentList.documentListData &&
      documentList.documentListData.documents &&
      documentList.documentListData.documents.length
    );
  };
  return (
    <div className={styles.documentListing}>
      {documentList && documentList.isDocumentListLoading ? (
        <Loader text={msgLoading} />
      ) : documentList && documentList.isDocumentListError ? (
        <Error text={msgError} />
      ) : hasDocumentRecords() ? (
        <div className={styles.documentListingInner}>
          {documentList &&
            documentList.documentListData &&
            documentList.documentListData.documents &&
            documentList.documentListData.documents.map((item, key) => {
              return (
                <div className={styles.docment} key={key}>
                  {item.link ? (
                    <div className={styles.name}>
                      <a href={item.link} target="_blank">
                        {item.name}
                      </a>
                    </div>
                  ) : (
                    <div className={styles.name}>{item.name}</div>
                  )}

                  <div className={styles.detail}>{item.description}</div>
                </div>
              );
            })}
        </div>
      ) : (
        <NoDataFound text={msgDataNotFound} />
      )}
    </div>
  );
}

export default DocumentListing;
